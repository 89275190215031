<template>
  <div v-if="!isValidUrl || onCreate">
    <v-text-field
      :loading="status || loading"
      :disabled="disabled"
      :label="label"
      @click="pickFile"
      v-model="valueOnTextField"
      color="tertiary"
      readonly
      :error-messages="errorMessages"
      :prepend-inner-icon="icon"
    >
      <v-scroll-x-transition slot="append">
        <v-icon v-if="done" color="success">
          mdi-check_circle
        </v-icon>
      </v-scroll-x-transition>
    </v-text-field>
    <input type="file" style="display: none" ref="fileInput" :accept="fileType" @change="onFilePicked" />
  </div>
  <div v-else>
    <v-layout row justify-start align-center>
      <v-btn
        flat
        :loading="status || loading"
        :disabled="status || loading"
        color="black"
        @click="loader = 'status'"
        :href="value"
      >        
        {{ $t("buttons.download") }}
        <v-icon right dark>mdi-download</v-icon>
      </v-btn>

      <v-btn :loading="status || loading" :disabled="status || loading" flat @click="removeFile">
        {{ $t("buttons.delete_document") }}
        <v-icon right color="red">mdi-delete</v-icon>
      </v-btn>
    </v-layout>
  </div>
</template>
<script>
  const FILES = {
    ONLY_IMAGES: "image/png,image/jpg,image/jpeg",
    ONLY_DOCS:
      ".doc,.docx,.xls,.txt,application/vnd.ms-excel,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.document.glossary+xml,application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml",
    ALL_FILES:
      "image/png,image/jpg,image/jpeg,.doc,.docx,.xls,.txt,application/vnd.ms-excel,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.document.glossary+xml,application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml",
    ONLY_EXCEL: "application/vnd.ms-excel",
    ONLY_PDF: "application/pdf",
    ONLY_WORD: ".doc,.docx"
  };
  export default {
    name: "FileUpload",
    props: {
      value: {
        required: true,
        validator: prop => typeof prop === "string" || prop === null
      },
      label: {
        type: String,
        required: false,
        default() {
          return this.$t("global.file");
        }
      },
      icon: {
        type: String,
        required: false,
        default: "mdi-attachment"
      },
      storageType: {
        type: String,
        required: true
      },
      errorMessages: {
        type: Array,
        required: false,
        default: () => []
      },
      onCreate: {
        type: Boolean,
        required: false,
        default: false
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      fileType: {
        type: String,
        required: false,
        default: () =>
          "image/png,image/jpg,image/jpeg,.doc,.docx,.xlsx,.xls,.txt,application/vnd.ms-excel,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.document.glossary+xml,application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml"
      }
    },
    data: vm => ({
      status: false,
      done: false,
      file: null,
      willBeRemovedLater: false,
      oneTimeWatch: false,
      initialValue: null
    }),
    computed: {
      isValidUrl() {
        const options = {
          protocols: ["http", "https", "ftp"],
          require_valid_protocol: true,
          require_protocol: true
        };
        return true;
      },
      valueOnTextField() {
        return this.onCreate ? this.file && this.file.name : this.value;
      }
    },
    watch: {
      value(val) {
        if (!this.oneTimeWatch) {
          this.initialValue = val;
          this.oneTimeWatch = true;
        } else {
          if (val === this.initialValue) {
            this.willBeRemovedLater = false;
          }
        }
      }
    },
    methods: {
      pickFile() {
        this.$refs.fileInput.click();
      },
      validateFile(file) {
        return new Promise((resolve, reject) => {
          const isFormalExtension =
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "application/msword" ||
            file.type === "application/pdf" ||
            file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml";

          const isLt2M = file.size / 1024 / 1024 < 4;
          if (!isFormalExtension) {
            reject({ message: "file_format_error" });
          }
          if (!isLt2M) {
            reject({ message: "less_than_2_mb_error" });
          }
          if (isFormalExtension && isLt2M) {
            resolve(isFormalExtension && isLt2M);
          }
          resolve(false);
        });
      },
      onFilePicked(e) {
        this.file = e.target.files[0];
        if (this.file !== undefined) {
          this.validateFile(this.file)
            .then(valid => {
              if (valid) {
                this.$emit("input", this.file.name);
              } else {
                // todo
              }
            })
            .catch(error => {
              this.$refs.fileInput.value = null;
              this.file = null;
              this.$emit("input", null);
            });
        } else {
          this.file = null;
          this.$emit("input", null);
        }
      },
      sendFile() {
        return new Promise((resolve, reject) => {
          if (this.file) {
            const fd = new FormData();
            fd.append("file", this.file, this.file.name);
            fd.append("storageType", this.storageType);
            this.status = true;
            this.done = false;
            if (this.willBeRemovedLater) {
              this.willBeRemovedLater = false;
              this.removeFileFromServer();
            }
            return this.$api.storageService
              .upload(fd, {
                onUploadProgress: progressEvent => {
                  //let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                }
              })
              .then(({ data, status }) => {
                this.status = false;
                if (status === 201) {
                  this.done = !this.onCreate;
                  this.oneTimeWatch = false;
                  this.file = null;
                  this.$emit("input", data.data);
                  resolve(data);
                } else {
                  resolve(false);
                }
              })
              .catch(error => {
                this.$emit("input", null);
                reject("The file could not be uploaded!");
              });
          } else {
            if (this.willBeRemovedLater) {
              this.willBeRemovedLater = false;
              this.removeFileFromServer();
            }
            resolve(true);
          }
        });
      },
      removeFileFromServer() {
        if (this.initialValue) {
          this.$api.storageService
            .delete(this.storageType, this.initialValue)
            .then(response => {
              if (!response.data.error) {
                this.file = null;
                this.$emit("input", null);
                this.$emit("remove");
              } else {
                console.log("The file could not be deleted!");
              }
            })
            .catch(() => {
              console.log("The file could not be deleted!");
            });
        }
      },
      removeFile() {
        this.done = false;
        this.willBeRemovedLater = true;
        this.$emit("input", null);
        // if (this.$attrs['data-vv-name']) {
        //     this.willBeRemovedLater = true;
        //     this.$emit("input", null);
        // } else {
        //     this.removeFileFromServer();
        // }
      }
    },
    created() {
      this.$emit("send", this.sendFile);
    }
  };
</script>
